//////////////////////////////////////////////////////////////////
///// Initials Functions   ///////////////////////////////////////
//////////////////////////////////////////////////////////////////

import $ from 'jquery';
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

// rotate Claim Wheel
const rotateAnimation = function () {
  $('.effect--rotate > svg').hover(
    function () {
      const $this = $(this);
      gsap.to($this, {
        rotation: '+=360_cw',
        duration: 15,
        ease: 'Linear.easeNone',
        repeat: -1
      });
    },
    function () {
      const $this = $(this);
      gsap.killTweensOf($this);
    }
  );
};

// Send GTAG Event
const triggerEvent = function () {
  $('.wa-trigger').on('click', function () {
    if (window.gtag !== undefined) {
      window.gtag('event', 'whatsapp', {
        event_category: 'engagement',
        event_label: 'general_lead'
      });
    }
  });
};

// Count up animation based on https://codepen.io/jshakes/pen/KKpjdYv
const statsCounter = function () {

    // Get YouTube Stats
    const subscribersYoutube = document.getElementById('subscribersYoutube');
    const videosYoutube = document.getElementById('videosYoutube');

    const channelID = 'UCDx6L69jmKBJbNu5GnkCilg';
    const apiKey = 'AIzaSyDpBIT3LcW8sJXZzWy2VXQvU66CKO8H1ow';

    let getYoutubeStats = () => {

    fetch(`https://www.googleapis.com/youtube/v3/channels?part=statistics&id=${channelID}&key=${apiKey}`)
      .then(response => {
        return response.json();
      })
      .then(data => {
        //console.log(data);
        //console.log(data["items"][0].statistics.subscriberCount);
        if (videosYoutube ) {
          videosYoutube.setAttribute('data-target', data["items"][0].statistics.videoCount);
        }
        if (subscribersYoutube ) {
          subscribersYoutube.setAttribute ('data-target', data["items"][0].statistics.subscriberCount);
        }
      });
    };

    if (subscribersYoutube ||  videosYoutube) {
      getYoutubeStats();
    }



  const animationDuration = 3000;
  const frameDuration = 1000 / 60;
  const totalFrames = Math.round( animationDuration / frameDuration );
  const easeOutQuad = t => t * ( 2 - t );

  const animateCountUp = function (item) {
    let frame = 0;
    const countTo = item.data('target');
    // Start the animation running 60 times per second
    const counter = setInterval( () => {
      frame++;
      const progress = easeOutQuad( frame / totalFrames );
      const currentCount = Math.round( countTo * progress );

      if ( item.html !== currentCount ) {
        item.html(currentCount);
      }

      if ( frame === totalFrames ) {
        clearInterval( counter );
      }
    }, frameDuration );
  };

  const statsCounterWrapper = $('.counter.is-active');
  if (!statsCounterWrapper.length) { return; }
  let triggerCounter = gsap.timeline({scrollTrigger: '.counter.is-active'});
  const startCounting = function (el) {
    el.each(function () {
      animateCountUp($(this));
    });
  };

  const statsCounterEl = $('.counter.is-active .counter__item .value');
  //console.log(statsCounterEl);
  triggerCounter.to(statsCounterWrapper, {duration: 1, onComplete: startCounting, onCompleteParams: [statsCounterEl]});
};

// Refresh aos states for accordion items
const refreshAccordion = function () {
  $('.accordion__item').on('click', function () {
    const counterWrapper = $('.counter', $(this));
    if(counterWrapper.length) {
      counterWrapper.addClass('is-active');

      if (counterWrapper.hasClass('is-active')) {
        statsCounter();
      }

    }
  });
};

const buttonContact = function () {
  $('#buttonContact').on('click', function (e) {
    e.preventDefault();
    $('html, body').animate({
      scrollTop: $("#kontakt").offset().top
    }, 1000);
  });
};

const flipCards = function() {
  const flipCardWrapper = Array.from(document.querySelectorAll('.flipCard'));
  if (!flipCardWrapper.length) { return; }
  
  const turnCard = function(event) {
    event.stopPropagation;
    event.currentTarget.classList.toggle('is-active')
  }

  flipCardWrapper.forEach(flipCard => {
    flipCard.addEventListener('click', (event) => { turnCard(event) }); 
  }); 

}

const typeAnimation = function() {
  const typeAnimationWrapper = $('.type-animation'); 
  if (!typeAnimationWrapper.length) { return; }
  const timeIntervall = 6000;
  const typeAnimationContainer = $('.type-animation__container');
  const typeAnimationContent = $('.type-animation__container > div');
  const typeAnimationContainerValues = typeAnimationContainer.data('strings');
  
  const textLoad = () => {
    typeAnimationContainerValues.forEach(function (item, index) {
      setTimeout(() => {
        typeAnimationContent.text(item);
      }, index*timeIntervall);
    });
  }

  textLoad();
  setInterval(textLoad, typeAnimationContainerValues.length*timeIntervall);
}

export default function initialFunctions() {
  $('body').removeClass('is-loading');
  rotateAnimation();
  //sendContactForm();
  triggerEvent();
  refreshAccordion();
  statsCounter();
  buttonContact();
  flipCards();
}