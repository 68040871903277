/**
 * Entry-point for the global dependencies
 */

/*
 * Style imports
 */
import '../css/main.scss';

/*
 * Image imports
 */
import '../img/blb-favicon-src.png';
import '../img/mag-favicon-src.png';
import '../img/honw-favicon-src.png';
import '../img/logo.svg';
import '../img/logo-blackboat.svg';
import '../img/logo-blackboat-typo.svg';
import '../img/honw-logo.svg';
import '../img/mag-logo.svg';
import '../img/wnc-logo.svg';
import '../img/sfo-logo.svg';
import '../img/tnx-logo.svg';
import '../img/404.gif';
import '../img/chevron.svg';
import '../img/chevron-down.svg';
import '../img/close.svg';
import '../img/pattern.svg';

/*
 * Script imports
 */
import $ from 'jquery';
import 'lazysizes';
import gsap from 'gsap';
import AOS from 'aos';
import barba from '@barba/core';

import header from './components/header';
import buildLoadingLayerController from './components/loading-layer';
import sendGoogleAnalyticsPageView from './components/analytics';
import initServiceWorker from './service-worker/sw-init';
import initialFunctions from './components/initialFunctions';
import socialFeed from "./components/socialfeed";
import botFrame from "./components/botFrame";
import botWidget from "./components/botWidget";

/******************************************
 * own functions
 *****************************************/

const pageTransition = $('.page-transition');
const loaderIn = function () {
  // GSAP tween to stretch the loading screen across the whole screen
  return gsap.fromTo(
    pageTransition,
    {
      rotation: 10,
      scaleX: 0,
      xPercent: -5
    },
    {
      duration: 0.5,
      xPercent: 0,
      scaleX: 1,
      rotation: 0,
      ease: 'power4.inOut',
      transformOrigin: 'left center'
    }
  );
};

const loaderAway = function () {
  // GSAP tween to hide loading screen
  return gsap.to(pageTransition, {
    duration: 0.5,
    scaleX: 0,
    xPercent: 5,
    rotation: -10,
    transformOrigin: 'right center',
    ease: 'power4.inOut'
  });
};

const loaderReset = function () {
  gsap.set(pageTransition, {
    scaleX: 0,
    rotation: 10,
    xPercent: -5,
    yPercent: -50,
    transformOrigin: 'left center',
    autoAlpha: 1
  });
};

const setActiveNavEntry = function (nextPage) {
  $('header [data-nav-entry!=""]').removeClass('is-active');
  if (nextPage.match('^case-detail-')) {
    nextPage = 'cases';
  }
  $(`li[data-nav-entry=${nextPage}]`).addClass('is-active');
};


const initForm = function() {
  if (window.Formie && document.getElementById('kontakt')) {
    //console.log('init form');
    let $form = document.getElementById("contact");
    let $formHash = $form.getAttribute('data-handle');
    //console.log($formHash);
    window.Formie.initForms() ;
    let $csrfInput = $form.querySelector('input[name="CRAFT_CSRF_TOKEN"]');
    fetch('/actions/formie/forms/refresh-tokens?form='+$formHash)
                    .then(result => { return result.json(); })
                    .then(result => { $csrfInput.outerHTML = result.csrf.input; });
  }
} 

/******************************************
 * on first load
 *****************************************/

/* check formie  
  document.addEventListener('onFormieInit', (e) => {
    let Formie = e.detail.formie;
    console.log(Formie)
  });
*/

$(() => {
  header();
  loaderReset();
  initialFunctions();
  botWidget();
  AOS.init({
    delay: 50,
    duration: 600,
    offset: 80,
    once: true,
    easing: 'ease-in-out'
  });
});

if (process.env.ENVIRONMENT !== 'dev') {
  initServiceWorker();
} else {
  // eslint-disable-next-line no-console
  console.log(
    `Skipping registration of service worker as application is running with ENVIRONMENT [${process.env.ENVIRONMENT}]`
  );
}

/******************************************
 * Page transitions
 *****************************************/

const BARBA_REQUEST_TIMEOUT = 10000; // amount of time to wait before barba triggers a hard page-reload
const BARBA_LOADING_TIMEOUT = 1500; // amount of time to wait before showing the loading-layer
const loadingLayerControllerPromise = buildLoadingLayerController(
  '.loading-layer',
  'loading-layer--active',
  'loading-layer--opaque',
  BARBA_LOADING_TIMEOUT
);

barba.init({
  //prefetchIgnore: true,
  timeout: BARBA_REQUEST_TIMEOUT,
  requestError: (trigger, action) => {
    if (action === 'click') {
      loadingLayerControllerPromise.then((controller) =>
        controller.activateOpaque()
      );
    }
  },
  transitions: [
    {
      name: 'fade',
      before() {
        loadingLayerControllerPromise.then((controller) =>
          controller.activate()
        );
      },
      beforeEnter(data) {
        loadingLayerControllerPromise.then((controller) =>
          controller.stopWaitForLoad()
        );

        // reset navigation and header states
        $('.header.is-open').removeClass('is-open');
        $('.hamburger.is-active').removeClass('is-active');
        $('.navFlyout.is-active').removeClass('is-active');
        $('html').removeClass('lock').removeClass('menu-open');

        $('html').removeClass('lock').removeClass('menu-open');

        /* Scroll up after fade, except for navigation between cases */
        if (data.current.namespace !== 'cases-detail') {
          $('body,html').delay(0).animate(
            {
              scrollTop: 0
            },
            0
          );
        }
        setActiveNavEntry(data.next.namespace);
      },
      async leave() {
        await loaderIn();
      },
      enter() {
        loaderAway();
      },
      afterEnter() {
        loadingLayerControllerPromise.then((controller) =>
        controller.deactivate()
        );
      },
      after() {
        // we need to wait a tick as even in the after event the document title is not updated yet.
        setTimeout(sendGoogleAnalyticsPageView, 0);
        //window.recaptcha_success();
        initialFunctions();
        initForm();
        $('header.bot').removeClass('header--inverted').removeClass('bot');
      }
    }
  ],
  views: [
    (() => {
      let initializedLifecycles = [];
      return {
        namespace: 'about',
        beforeEnter() {
          import(
            /* webpackChunkName: "about", webpackPrefetch: true */ './components/carousel'
          ).then(({ default: initCarousel }) => {
            initCarousel().then((carouselLifecycle) => {
              initializedLifecycles = [
                ...initializedLifecycles,
                carouselLifecycle
              ];
            });
          });

          import(
            /* webpackChunkName: "about", webpackPrefetch: true */ './components/video'
          ).then(({ default: initVideo }) => {
            initVideo().then((videoLifecycle) => {
              initializedLifecycles = [
                ...initializedLifecycles,
                videoLifecycle
              ];
            });
          });

          import(
            /* webpackChunkName: "about", webpackPrefetch: true */ './components/timeline'
            ).then(({ default: initTimeline }) => {
            initTimeline().then((timelineLifecycle) => {
              initializedLifecycles = [
                ...initializedLifecycles,
                timelineLifecycle
              ];
            });
          });

          import(
            /* webpackChunkName: "botWidget", webpackPrefetch: true */ './components/botWidget'
            ).then(({ default: initBotWidget }) => {
              initBotWidget().then((botWidgetLifecycle) => {
              initializedLifecycles = [
                ...initializedLifecycles,
                botWidgetLifecycle
              ];
            });
          });


        },
        afterLeave: () => {
          initializedLifecycles.forEach((lifecycle) => lifecycle.destroy());
          initializedLifecycles = [];
        }
      };
    })(),
    (() => {
      let initializedLifecycles = [];
      return {
        namespace: 'cases',
        beforeEnter() {
          import(
            /* webpackChunkName: "cases", webpackPrefetch: true */ './components/carousel'
          ).then(({ default: initCarousel }) => {
            initCarousel().then((carouselLifecycle) => {
              initializedLifecycles = [
                ...initializedLifecycles,
                carouselLifecycle
              ];
            });
          });
          import(
            /* webpackChunkName: "cases", webpackPrefetch: true */ './components/video'
          ).then(({ default: initVideo }) => {
            initVideo().then((videoLifecycle) => {
              initializedLifecycles = [
                ...initializedLifecycles,
                videoLifecycle
              ];
            });
          });
          import(
            /* webpackChunkName: "botWidget", webpackPrefetch: true */ './components/botWidget'
            ).then(({ default: initBotWidget }) => {
              initBotWidget().then((botWidgetLifecycle) => {
              initializedLifecycles = [
                ...initializedLifecycles,
                botWidgetLifecycle
              ];
            });
          });
        },
        afterLeave: () => {
          initializedLifecycles.forEach((lifecycle) => lifecycle.destroy());
          initializedLifecycles = [];
        }
      };
    })(),
    (() => {
      let initializedLifecycles = [];
      return {
        namespace: 'cases-detail',
        beforeEnter() {
          import(
            /* webpackChunkName: "cases-detail", webpackPrefetch: true */ './components/carousel'
          ).then(({ default: initCarousel }) => {
            initCarousel().then((carouselLifecycle) => {
              initializedLifecycles = [
                ...initializedLifecycles,
                carouselLifecycle
              ];
            });
          });

          import(
            /* webpackChunkName: "cases-detail", webpackPrefetch: true */ './components/video'
          ).then(({ default: initVideo }) => {
            initVideo().then((videoLifecycle) => {
              initializedLifecycles = [
                ...initializedLifecycles,
                videoLifecycle
              ];
            });
          });
          import(
            /* webpackChunkName: "botWidget", webpackPrefetch: true */ './components/botWidget'
            ).then(({ default: initBotWidget }) => {
              initBotWidget().then((botWidgetLifecycle) => {
              initializedLifecycles = [
                ...initializedLifecycles,
                botWidgetLifecycle
              ];
            });
          });
        },
        afterLeave: () => {
          initializedLifecycles.forEach((lifecycle) => lifecycle.destroy());
          initializedLifecycles = [];
        }
      };
    })(),
    (() => {
      let initializedLifecycles = [];
      return {
        namespace: 'home',
        beforeEnter() {
          import(
            /* webpackChunkName: "home", webpackPrefetch: true */ './components/carousel'
          ).then(({ default: initCarousel }) => {
            initCarousel().then((carouselLifecycle) => {
              initializedLifecycles = [
                ...initializedLifecycles,
                carouselLifecycle
              ];
            });
          });
          import(
            /* webpackChunkName: "home", webpackPrefetch: true */ './components/video'
          ).then(({ default: initVideo }) => {
            initVideo().then((videoLifecycle) => {
              initializedLifecycles = [
                ...initializedLifecycles,
                videoLifecycle
              ];
            });
          });
          import(
            /* webpackChunkName: "botWidget", webpackPrefetch: true */ './components/botWidget'
            ).then(({ default: initBotWidget }) => {
              initBotWidget().then((botWidgetLifecycle) => {
              initializedLifecycles = [
                ...initializedLifecycles,
                botWidgetLifecycle
              ];
            });
          });
        },
        afterLeave: () => {
          initializedLifecycles.forEach((lifecycle) => lifecycle.destroy());
          initializedLifecycles = [];
        }
      };
    })(),
    (() => {
      let initializedLifecycles = [];
      return {
        namespace: 'jobs',
        beforeEnter() {
          import(
            /* webpackChunkName: "jobs", webpackPrefetch: true */ './components/carousel'
          ).then(({ default: initCarousel }) => {
            initCarousel().then((carouselLifecycle) => {
              initializedLifecycles = [
                ...initializedLifecycles,
                carouselLifecycle
              ];
            });
          });
          import(
            /* webpackChunkName: "jobs", webpackPrefetch: true */ './components/video'
          ).then(({ default: initVideo }) => {
            initVideo().then((videoLifecycle) => {
              initializedLifecycles = [
                ...initializedLifecycles,
                videoLifecycle
              ];
            });
          });
          import(
            /* webpackChunkName: "botWidget", webpackPrefetch: true */ './components/botWidget'
            ).then(({ default: initBotWidget }) => {
              initBotWidget().then((botWidgetLifecycle) => {
              initializedLifecycles = [
                ...initializedLifecycles,
                botWidgetLifecycle
              ];
            });
          });
        },
        afterLeave: () => {
          initializedLifecycles.forEach((lifecycle) => lifecycle.destroy());
          initializedLifecycles = [];
        }
      };
    })(),
    (() => {
      let initializedLifecycles = [];
      return {
        namespace: 'new-work',
        beforeEnter() {
          import(
            /* webpackChunkName: "new-work", webpackPrefetch: true */ './components/carousel'
          ).then(({ default: initCarousel }) => {
            initCarousel().then((carouselLifecycle) => {
              initializedLifecycles = [
                ...initializedLifecycles,
                carouselLifecycle
              ];
            });
          });
          import(
            /* webpackChunkName: "new-work", webpackPrefetch: true */ './components/video'
          ).then(({ default: initVideo }) => {
            initVideo().then((videoLifecycle) => {
              initializedLifecycles = [
                ...initializedLifecycles,
                videoLifecycle
              ];
            });
          });
          import(
            /* webpackChunkName: "botWidget", webpackPrefetch: true */ './components/botWidget'
            ).then(({ default: initBotWidget }) => {
              initBotWidget().then((botWidgetLifecycle) => {
              initializedLifecycles = [
                ...initializedLifecycles,
                botWidgetLifecycle
              ];
            });
          });
        },
        afterLeave: () => {
          initializedLifecycles.forEach((lifecycle) => lifecycle.destroy());
          initializedLifecycles = [];
        }
      };
    })(),
    (() => {
      let initializedLifecycles = [];
      return {
        namespace: 'services',
        beforeEnter() {
          
          import(
            /* webpackChunkName: "services", webpackPrefetch: true */ './components/carousel'
          ).then(({ default: initCarousel }) => {
            initCarousel().then((carouselLifecycle) => {
              initializedLifecycles = [
                ...initializedLifecycles,
                carouselLifecycle
              ];
            });
          });
          import(
            /* webpackChunkName: "about", webpackPrefetch: true */ './components/timeline'
            ).then(({ default: initTimeline }) => {
            initTimeline().then((timelineLifecycle) => {
              initializedLifecycles = [
                ...initializedLifecycles,
                timelineLifecycle
              ];
            });
          });
          import(
            /* webpackChunkName: "services", webpackPrefetch: true */ './components/video'
          ).then(({ default: initVideo }) => {
            initVideo().then((videoLifecycle) => {
              initializedLifecycles = [
                ...initializedLifecycles,
                videoLifecycle
              ];
            });
          });
          import(
            /* webpackChunkName: "botWidget", webpackPrefetch: true */ './components/botWidget'
            ).then(({ default: initBotWidget }) => {
              initBotWidget().then((botWidgetLifecycle) => {
              initializedLifecycles = [
                ...initializedLifecycles,
                botWidgetLifecycle
              ];
            });
          });
        },
        afterLeave: () => {
          initializedLifecycles.forEach((lifecycle) => lifecycle.destroy());
          initializedLifecycles = [];
        }
      };
    })(),
    (() => {
      let initializedLifecycles = [];
      return {
        namespace: 'detail',
        beforeEnter() {
          
          import(
            /* webpackChunkName: "cases-detail", webpackPrefetch: true */ './components/carousel'
            ).then(({ default: initCarousel }) => {
            initCarousel().then((carouselLifecycle) => {
              initializedLifecycles = [
                ...initializedLifecycles,
                carouselLifecycle
              ];
            });
          });
          import(
            /* webpackChunkName: "about", webpackPrefetch: true */ './components/timeline'
            ).then(({ default: initTimeline }) => {
            initTimeline().then((timelineLifecycle) => {
              initializedLifecycles = [
                ...initializedLifecycles,
                timelineLifecycle
              ];
            });
          });
          import(
            /* webpackChunkName: "detail", webpackPrefetch: true */ './components/video'
          ).then(({ default: initVideo }) => {
            initVideo().then((videoLifecycle) => {
              initializedLifecycles = [
                ...initializedLifecycles,
                videoLifecycle
              ];
            });
          });
          import(
            /* webpackChunkName: "botWidget", webpackPrefetch: true */ './components/botWidget'
            ).then(({ default: initBotWidget }) => {
              initBotWidget().then((botWidgetLifecycle) => {
              initializedLifecycles = [
                ...initializedLifecycles,
                botWidgetLifecycle
              ];
            });
          });
        },
        afterLeave: () => {
          initializedLifecycles.forEach((lifecycle) => lifecycle.destroy());
          initializedLifecycles = [];
        }
      };
    })(),
    (() => {
      let initializedLifecycles = [];
      return {
        namespace: 'magHome',
        beforeEnter() {
          import(
            /* webpackChunkName: "socialFeed", webpackPrefetch: true */ './components/socialfeed'
            ).then(({ default: initSocialFeed }) => {
            initSocialFeed().then((socialFeedLifecycle) => {
              initializedLifecycles = [
                ...initializedLifecycles,
                socialFeedLifecycle
              ];
            });
          });
          import(
            /* webpackChunkName: "home", webpackPrefetch: true */ './components/video'
          ).then(({ default: initVideo }) => {
            initVideo().then((videoLifecycle) => {
              initializedLifecycles = [
                ...initializedLifecycles,
                videoLifecycle
              ];
            });
          });
          import(
            /* webpackChunkName: "detail", webpackPrefetch: true */ './components/carousel'
            ).then(({ default: initCarousel }) => {
            initCarousel().then((carouselLifecycle) => {
              initializedLifecycles = [
                ...initializedLifecycles,
                carouselLifecycle
              ];
            });
          });
          import(
            /* webpackChunkName: "botWidget", webpackPrefetch: true */ './components/botWidget'
            ).then(({ default: initBotWidget }) => {
              initBotWidget().then((botWidgetLifecycle) => {
              initializedLifecycles = [
                ...initializedLifecycles,
                botWidgetLifecycle
              ];
            });
          });
        },
        afterEnter() {
          socialFeed();
          //initFormie();
        },
        afterLeave: () => {
          initializedLifecycles.forEach((lifecycle) => lifecycle.destroy());
          initializedLifecycles = [];
        }
      };
    })(),
    (() => {
      let initializedLifecycles = [];
      return {
        namespace: 'honwHome',
        beforeEnter() {
          import(
            /* webpackChunkName: "home", webpackPrefetch: true */ './components/video'
            ).then(({ default: initVideo }) => {
            initVideo().then((videoLifecycle) => {
              initializedLifecycles = [
                ...initializedLifecycles,
                videoLifecycle
              ];
            });
          });
          import(/* webpackChunkName: "contact" */ './components/maps').then(({ default: initMap }) => {
            initMap();
          });
          import(
            /* webpackChunkName: "detail", webpackPrefetch: true */ './components/carousel'
            ).then(({ default: initCarousel }) => {
            initCarousel().then((carouselLifecycle) => {
              initializedLifecycles = [
                ...initializedLifecycles,
                carouselLifecycle
              ];
            });
          });
          import(
            /* webpackChunkName: "botWidget", webpackPrefetch: true */ './components/botWidget'
            ).then(({ default: initBotWidget }) => {
              initBotWidget().then((botWidgetLifecycle) => {
              initializedLifecycles = [
                ...initializedLifecycles,
                botWidgetLifecycle
              ];
            });
          });
        },
        afterLeave: () => {
          initializedLifecycles.forEach((lifecycle) => lifecycle.destroy());
          initializedLifecycles = [];
        }
      };
    })(),
    (() => {
      let initializedLifecycles = [];
      return {
        namespace: 'sfoHome',
        beforeEnter() {
          import(
            /* webpackChunkName: "home", webpackPrefetch: true */ './components/video'
            ).then(({ default: initVideo }) => {
            initVideo().then((videoLifecycle) => {
              initializedLifecycles = [
                ...initializedLifecycles,
                videoLifecycle
              ];
            });
          });
          import(
            /* webpackChunkName: "botWidget", webpackPrefetch: true */ './components/botWidget'
            ).then(({ default: initBotWidget }) => {
              initBotWidget().then((botWidgetLifecycle) => {
              initializedLifecycles = [
                ...initializedLifecycles,
                botWidgetLifecycle
              ];
            });
          });
        },
        afterEnter() {
        },
        afterLeave: () => {
          initializedLifecycles.forEach((lifecycle) => lifecycle.destroy());
          initializedLifecycles = [];
        }
      };
    })(),
    (() => {
      let initializedLifecycles = [];
      return {
        namespace: 'wncHome',
        beforeEnter() {
          import(
            /* webpackChunkName: "home", webpackPrefetch: true */ './components/video'
            ).then(({ default: initVideo }) => {
            initVideo().then((videoLifecycle) => {
              initializedLifecycles = [
                ...initializedLifecycles,
                videoLifecycle
              ];
            });
          });
          import(
            /* webpackChunkName: "detail", webpackPrefetch: true */ './components/carousel'
            ).then(({ default: initCarousel }) => {
            initCarousel().then((carouselLifecycle) => {
              initializedLifecycles = [
                ...initializedLifecycles,
                carouselLifecycle
              ];
            });
          });
          import(
            /* webpackChunkName: "botWidget", webpackPrefetch: true */ './components/botWidget'
            ).then(({ default: initBotWidget }) => {
              initBotWidget().then((botWidgetLifecycle) => {
              initializedLifecycles = [
                ...initializedLifecycles,
                botWidgetLifecycle
              ];
            });
          });
        },
        afterEnter() {
        },
        afterLeave: () => {
          initializedLifecycles.forEach((lifecycle) => lifecycle.destroy());
          initializedLifecycles = [];
        }
      };
    })(),
    (() => {
      return {
        namespace: 'newsletter',
        beforeEnter() {
          
        },
        afterEnter()  {
         let limit = $('#newsletterList').data('limit');
         const scriptSrc = '//blackboat.us6.list-manage.com/generate-js/?u=8689df1ba61795ca8a8521851&fid=22931&show='+limit;
         window.postscribe('#newsletterList', '<script src='+scriptSrc+'></script>');
        }
      };
    })(),
    (() => {
      let initializedLifecycles = [];
      return {
        namespace: 'bot',
        beforeEnter() {
          $('body').removeClass('page-blb').addClass('bot')
          $('header').addClass('bot header--inverted');
          import(
            /* webpackChunkName: "bot", webpackPrefetch: true */ './components/botFrame'
            ).then(({ default: initBot }) => {
              initBot().then((botLifecycle) => {
              initializedLifecycles = [
                ...initializedLifecycles,
                botLifecycle
              ];
            });
          });
        },
        afterEnter() {
          //bot();
        },
        afterLeave: () => {
          $('body').addClass('page-blb').removeClass('bot')
          initializedLifecycles.forEach((lifecycle) => lifecycle.destroy());
          initializedLifecycles = [];
        }
      };
    })()
  ]
});
